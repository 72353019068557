var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "app-button",
        {
          staticStyle: { width: "100%" },
          attrs: { cssClass: "foot" },
          nativeOn: {
            click: function($event) {
              return _vm.showCustomerCreateForm.apply(null, arguments)
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("customers.newCustomer")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }