var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "appointment-header" },
      [
        _c("div", { staticClass: "e-subject" }, [
          _vm._v(_vm._s(_vm.data.Subject))
        ]),
        _vm.data.ServiceId > 0
          ? _c(
              "ejs-tooltip",
              {
                attrs: {
                  showTipPointer: false,
                  content: _vm.tooltipContent,
                  beforeRender: _vm.tooltipOnBeforeRender,
                  target: ".e-chip"
                }
              },
              [_c("app-chips", { attrs: { values: _vm.getChipValues } })],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "e-service" }, [
      _vm._v(_vm._s(_vm.data.ServiceName))
    ]),
    _c("div", { staticClass: "e-time" }, [
      _vm._v(
        _vm._s(_vm.formatTime(_vm.data.StartTime)) +
          " - " +
          _vm._s(_vm.formatTime(_vm.data.EndTime))
      )
    ]),
    _c("div", { staticClass: "e-notes" }, [_vm._v(_vm._s(_vm.data.Notes))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }