var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "template-wrap" }, [
    _c("div", { staticClass: "resource-image" }, [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.data.resourceData.image,
            expression: "data.resourceData.image"
          }
        ],
        attrs: { src: _vm.data.resourceData.image }
      })
    ]),
    _c("div", { staticClass: "resource-details" }, [
      _c("div", { staticClass: "resource-name" }, [
        _vm._v(_vm._s(_vm.data.resourceData.name))
      ]),
      _c("div", { staticClass: "resource-designation" }, [
        _vm._v(_vm._s(_vm.data.resourceData.designation))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }