var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("calendar.menu")))]),
      _c(
        "div",
        {
          staticClass: "filter-container",
          staticStyle: { "margin-bottom": "2px" }
        },
        [
          _c("app-dropdownlist", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasMultipleOffices,
                expression: "hasMultipleOffices"
              }
            ],
            attrs: {
              id: "officeId",
              placeholder: _vm.$t("offices.office"),
              dataSource: _vm.offices,
              fields: { text: "name", value: "id" }
            },
            on: { input: _vm.onOfficeIdValueChanged },
            model: {
              value: _vm.selectedOfficeId,
              callback: function($$v) {
                _vm.selectedOfficeId = $$v
              },
              expression: "selectedOfficeId"
            }
          }),
          _c("app-dropdownlist", {
            attrs: {
              id: "calendarTypeId",
              placeholder: _vm.$t("calendarTypes.calendarType"),
              dataSource: _vm.calendarTypesData,
              fields: { text: "name", value: "id" }
            },
            on: { input: _vm.onCalendarTypeIdValueChanged },
            model: {
              value: _vm.selectedCalendarTypeId,
              callback: function($$v) {
                _vm.selectedCalendarTypeId = $$v
              },
              expression: "selectedCalendarTypeId"
            }
          }),
          _c(
            "div",
            { staticClass: "employee-selector" },
            [
              _c("ejs-multiselect", {
                attrs: {
                  id: "employeeSelector",
                  dataSource: _vm.employeeSelector,
                  placeholder: _vm.$t("employees.employees"),
                  floatLabelType: "Always",
                  mode: "CheckBox",
                  fields: { text: "fullName", value: "id" }
                },
                on: { modelchanged: _vm.onEmployeeSelectorValueChanged },
                model: {
                  value: _vm.selectedEmployeeIds,
                  callback: function($$v) {
                    _vm.selectedEmployeeIds = $$v
                  },
                  expression: "selectedEmployeeIds"
                }
              })
            ],
            1
          ),
          _c("app-dropdownlist", {
            attrs: {
              id: "resourceGroupingSelector",
              placeholder: _vm.$t("calendar.resourcesGrouping"),
              dataSource: _vm.resourcesGroupingData,
              fields: { text: "name", value: "id" }
            },
            on: { input: _vm.onResourceGroupingIdValueChanged },
            model: {
              value: _vm.resourceGroupingId,
              callback: function($$v) {
                _vm.resourceGroupingId = $$v
              },
              expression: "resourceGroupingId"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("app-timepicker", {
            attrs: {
              id: "closingTime",
              placeholder: _vm.$t("calendar.startTime"),
              openOnFocus: true
            },
            model: {
              value: _vm.startTime,
              callback: function($$v) {
                _vm.startTime = $$v
              },
              expression: "startTime"
            }
          }),
          _c("app-timepicker", {
            attrs: {
              id: "closingTime",
              placeholder: _vm.$t("calendar.endTime"),
              openOnFocus: true
            },
            model: {
              value: _vm.endTime,
              callback: function($$v) {
                _vm.endTime = $$v
              },
              expression: "endTime"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "app-button",
            {
              attrs: {
                cssClass: "e-primary e-outline",
                disabled: !_vm.isReady
              },
              nativeOn: {
                click: function($event) {
                  return _vm.openFirstAvailabilityDialog.apply(null, arguments)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("calendar.firstAvailability.search")) + " ")]
          ),
          _c(
            "app-button",
            {
              attrs: {
                cssClass: "e-secondary e-outline",
                disabled: !_vm.isReady
              },
              nativeOn: {
                click: function($event) {
                  return _vm.openAppointmentSearchDialog.apply(null, arguments)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("calendar.appointments.search")) + " ")]
          )
        ],
        1
      ),
      _c("ejs-dialog", {
        ref: "newCustomerDialog",
        attrs: {
          cssClass: "new-customer-dialog",
          content: _vm.newCustomerDialogContentTemplate,
          isModal: true,
          width: "460px",
          target: "body",
          visible: false
        }
      }),
      _c(
        "ejs-schedule",
        {
          ref: "calendar",
          attrs: {
            id: "calendar",
            cssClass: "groupediting",
            timeScale: {
              enable: true,
              interval: 60,
              slotCount: 2
            },
            currentView: "Day",
            views: _vm.views,
            enableRecurrenceValidation: _vm.enableRecurrenceValidation,
            eventSettings: _vm.eventSettings,
            resourceHeaderTemplate: _vm.resourceHeaderTemplate,
            editorTemplate: _vm.editorTemplate,
            showQuickInfo: false,
            popupClose: _vm.popupClose,
            group: _vm.group,
            popupOpen: _vm.onPopupOpen,
            dataBinding: _vm.dataBinding,
            dataBound: _vm.dataBound,
            eventRendered: _vm.eventRendered,
            selectedDate: _vm.selectedDate,
            firstDayOfWeek: _vm.firstDayOfWeek,
            allowDragAndDrop: true,
            dragStart: _vm.onDragStart,
            dragStop: _vm.onDragStop,
            allowResizing: true,
            resizeStart: _vm.onResizeStart,
            resizeStop: _vm.onResizeStop,
            workDays: _vm.workDays,
            workHours: _vm.workHours,
            actionFailure: _vm.actionFailure,
            startHour: _vm.calendarStartHour,
            endHour: _vm.calendarEndHour,
            height: _vm.calendarHeight
          }
        },
        [
          _c(
            "e-resources",
            [
              _c("e-resource", {
                attrs: {
                  field: "EmployeeId",
                  title: "Employee",
                  name: "Employees",
                  dataSource: _vm.employeesResource,
                  textField: "name",
                  idField: "id",
                  startHourField: "startHour",
                  endHourField: "endHour",
                  allowMultiple: true
                }
              }),
              _c("e-resource", {
                attrs: {
                  field: "RoomId",
                  title: "Room",
                  name: "Rooms",
                  dataSource: _vm.roomsResource,
                  textField: "name",
                  idField: "id",
                  startHourField: "startHour",
                  endHourField: "endHour",
                  allowMultiple: true
                }
              }),
              _c("e-resource", {
                attrs: {
                  field: "EquipmentId",
                  title: "Equipment",
                  name: "Equipments",
                  dataSource: _vm.equipmentsResource,
                  textField: "name",
                  idField: "id",
                  startHourField: "startHour",
                  endHourField: "endHour",
                  allowMultiple: true
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.isReady
        ? _c("ejs-dialog", {
            ref: "firstAvailabilityDialog",
            attrs: {
              header: _vm.$t("calendar.firstAvailability.searchTitle"),
              cssClass: "first-availability-dialog",
              content: _vm.firstAvailabilityDialogContentTemplate,
              isModal: true,
              width: "550px",
              height: "90%",
              target: "body",
              visible: false,
              services: _vm.availableServices,
              showCloseIcon: true
            }
          })
        : _vm._e(),
      _vm.isReady
        ? _c("ejs-dialog", {
            ref: "appointmentSearchDialog",
            attrs: {
              header: _vm.$t("calendar.appointments.searchTitle"),
              cssClass: "first-availability-dialog",
              content: _vm.appointmentsSearchDialogContentTemplate,
              isModal: true,
              width: "550px",
              "max-height": "90%",
              target: "body",
              visible: false,
              showCloseIcon: true
            }
          })
        : _vm._e(),
      _c("appointment-search-toolbar-dialog", {
        ref: "appointmentSearchResultDialog"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }