var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._l(_vm.customers, function(customer) {
        return [
          _c("app-textbox", {
            key: `notes_${customer.id}`,
            attrs: {
              id: `notes_${customer.id}`,
              placeholder: `Note ${customer.name}`,
              cssClass: "appointment-customer-notes"
            },
            model: {
              value: customer.notes,
              callback: function($$v) {
                _vm.$set(customer, "notes", $$v)
              },
              expression: "customer.notes"
            }
          }),
          _c("app-dropdownlist-appointment-cancel-type", {
            key: `cancelType_${customer.id}`,
            staticStyle: { margin: "6px 0 12px" },
            attrs: { id: `cancelType_${customer.id}`, cssClass: "e-field" },
            model: {
              value: customer.cancelType,
              callback: function($$v) {
                _vm.$set(customer, "cancelType", $$v)
              },
              expression: "customer.cancelType"
            }
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }