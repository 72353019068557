var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ejs-dropdownlist", {
        ref: _vm.id,
        class: _vm.cssClass,
        attrs: {
          id: _vm.id,
          placeholder: _vm.$t("customers.customer"),
          dataSource: _vm.dataSource,
          floatLabelType: "Always",
          fields: { text: "fullName", value: "id" },
          value: _vm.internalValue,
          "data-msg-containerid": _vm.dataMsgContainerId,
          footerTemplate: _vm.footerTemplate,
          filtering: _vm.filtering,
          showClearButton: true,
          allowFiltering: "true"
        },
        on: { modelchanged: _vm.emitValue }
      }),
      _c("div", { attrs: { id: _vm.dataMsgContainerId } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }