var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ejs-multiselect", {
        ref: _vm.id,
        class: _vm.cssClass,
        attrs: {
          id: _vm.id,
          placeholder: _vm.$t("customers.customers"),
          dataSource: _vm.dataSource,
          floatLabelType: "Always",
          allowFiltering: true,
          fields: { text: "fullName", value: "id" },
          "data-msg-containerid": _vm.dataMsgContainerId,
          footerTemplate: _vm.footerTemplate,
          filtering: _vm.filtering
        },
        on: { modelchanged: _vm.emitValue },
        model: {
          value: _vm.internalValue,
          callback: function($$v) {
            _vm.internalValue = $$v
          },
          expression: "internalValue"
        }
      }),
      _c("div", { attrs: { id: _vm.dataMsgContainerId } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }