var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "appointment-tooltip" },
    [
      _c("div", { staticClass: "subject" }, [_vm._v(_vm._s(_vm.data.Subject))]),
      _c(
        "table",
        { staticClass: "appointment-tooltip-table" },
        [
          _c("tr", [
            _c("th", [_vm._v("Servizio:")]),
            _c("td", [_vm._v(_vm._s(_vm.data.ServiceName))])
          ]),
          _c("tr", [
            _c("th", [_vm._v("Data e ora:")]),
            _c("td", [
              _vm._v(
                _vm._s(_vm.formatDate(_vm.data.StartTime)) +
                  " " +
                  _vm._s(_vm.formatTime(_vm.data.StartTime)) +
                  " - " +
                  _vm._s(_vm.formatTime(_vm.data.EndTime))
              )
            ])
          ]),
          _c("tr", [
            _c("th", [_vm._v("Professionista:")]),
            _c("td", [_vm._v(_vm._s(_vm.data.EmployeeName))])
          ]),
          _c("tr", [
            _c("th", [_vm._v("Valutazone:")]),
            _c("td", [
              _vm._v(_vm._s(_vm.getYesNoDescription(_vm.data.IsTrial)))
            ])
          ]),
          _c(
            "tr",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.data.Place,
                  expression: "data.Place"
                }
              ]
            },
            [
              _c("th", [_vm._v("Luogo:")]),
              _c("td", [_vm._v(_vm._s(_vm.data.Place))])
            ]
          ),
          _c(
            "tr",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.data.RoomName,
                  expression: "data.RoomName"
                }
              ]
            },
            [
              _c("th", [_vm._v("Stanza:")]),
              _c("td", [_vm._v(_vm._s(_vm.data.RoomName))])
            ]
          ),
          _c(
            "tr",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.data.EquipmentName,
                  expression: "data.EquipmentName"
                }
              ]
            },
            [
              _c("th", [_vm._v("Attrezzatura:")]),
              _c("td", [_vm._v(_vm._s(_vm.data.EquipmentName))])
            ]
          ),
          _c("tr"),
          _c("tr"),
          _vm.data.CustomerId &&
          _vm.data.CustomerNotes != null &&
          _vm.data.CustomerNotes != ""
            ? _c("tr", [
                _c("th", [
                  _vm._v(
                    "Note " +
                      _vm._s(_vm.getCustomerName(_vm.data.CustomerId)) +
                      ":"
                  )
                ]),
                _c("td", [_vm._v(_vm._s(_vm.data.CustomerNotes))])
              ])
            : _vm._e(),
          _vm.data.Customers != null
            ? _vm._l(
                _vm.data.Customers.filter(
                  customer =>
                    customer.CustomerNotes != null &&
                    customer.CustomerNotes != ""
                ),
                function(customer) {
                  return _c("tr", { key: customer.CustomerId }, [
                    _c("th", [
                      _vm._v(
                        "Note " +
                          _vm._s(_vm.getCustomerName(customer.CustomerId)) +
                          ":"
                      )
                    ]),
                    _c("td", [_vm._v(_vm._s(customer.CustomerNotes))])
                  ])
                }
              )
            : _vm._e(),
          _c("tr", [
            _c("th", [_vm._v("Note appuntamento:")]),
            _c("td", [_vm._v(_vm._s(_vm.data.Notes))])
          ])
        ],
        2
      ),
      _vm.data.ServiceId > 0
        ? [
            _c("div", { staticClass: "payment" }, [
              _vm._v(_vm._s(_vm.getPaymentStatus))
            ]),
            _vm.data.CustomerIds != null &&
            _vm.data.CustomerIds.length > 0 &&
            _vm.data.PaidCustomers
              ? _vm._l(_vm.data.PaidCustomers, function(customerName, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { "margin-left": "2px" } },
                    [_vm._v(_vm._s(customerName))]
                  )
                })
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }