var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "appointments-content" } },
    [
      _vm._l(_vm.appointments.data, function(appointment) {
        return _c("div", { key: appointment.id }, [
          _c(
            "div",
            {
              class: ["e-card", { past: _vm.isPastAppointment(appointment) }],
              on: {
                click: function($event) {
                  return _vm.findAppointment(appointment)
                }
              }
            },
            [
              appointment.subject != null
                ? _c("div", { staticClass: "e-card-header" }, [
                    _c(
                      "div",
                      { staticClass: "e-card-header-caption" },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              "e-card-title",
                              { past: _vm.isPastAppointment(appointment) }
                            ]
                          },
                          [_vm._v(_vm._s(appointment.subject))]
                        ),
                        _c(
                          "ejs-tooltip",
                          {
                            attrs: {
                              showTipPointer: false,
                              content: _vm.tooltipContent,
                              beforeRender: _vm.tooltipOnBeforeRender,
                              target: ".e-chip"
                            }
                          },
                          [
                            appointment.paymentStatus
                              ? _c("app-chips", {
                                  attrs: {
                                    values: _vm.getChipValues(appointment)
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "e-card-content" }, [
                _c(
                  "table",
                  [
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("shared.date")) + ":")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.formatDate(appointment.startTime)) +
                            " " +
                            _vm._s(_vm.formatTime(appointment.startTime)) +
                            " - " +
                            _vm._s(_vm.formatTime(appointment.endTime))
                        )
                      ])
                    ]),
                    appointment.customerFullName != null
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                appointment.customerFullName.indexOf(",") >= 0
                                  ? _vm.$t("customers.customers")
                                  : _vm.$t("customers.customer")
                              ) + ":"
                            )
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(appointment.customerFullName))
                          ])
                        ])
                      : _vm._e(),
                    _vm.hasMultipleOffices && appointment.officeName != null
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("offices.office")) + ":")
                          ]),
                          _c("td", [_vm._v(_vm._s(appointment.officeName))])
                        ])
                      : _vm._e(),
                    appointment.serviceName != null
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("services.service")) + ":")
                          ]),
                          _c("td", [_vm._v(_vm._s(appointment.serviceName))])
                        ])
                      : _vm._e(),
                    appointment.employeeFullName != null
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("employees.employee")) + ":")
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(appointment.employeeFullName))
                          ])
                        ])
                      : _vm._e(),
                    appointment.isTrial != null
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("calendar.trial")) + ":")
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.getYesNoValue(appointment.isTrial))
                            )
                          ])
                        ])
                      : _vm._e(),
                    appointment.place != null
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("calendar.location")))
                          ]),
                          _c("td", [_vm._v(_vm._s(appointment.place))])
                        ])
                      : _vm._e(),
                    appointment.roomName != null
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("rooms.room")) + ":")
                          ]),
                          _c("td", [_vm._v(_vm._s(appointment.roomName))])
                        ])
                      : _vm._e(),
                    appointment.equipmentName != null
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("equipments.equipment")) + ":")
                          ]),
                          _c("td", [_vm._v(_vm._s(appointment.equipmentName))])
                        ])
                      : _vm._e(),
                    appointment.customers != null
                      ? _vm._l(
                          appointment.customers.filter(
                            customer =>
                              customer.customerNotes != null &&
                              customer.customerNotes != ""
                          ),
                          function(customer) {
                            return _c("tr", { key: customer.customerId }, [
                              _c("th", [
                                _vm._v(
                                  "Note " +
                                    _vm._s(customer.customerFullName) +
                                    ":"
                                )
                              ]),
                              _c("td", [_vm._v(_vm._s(customer.customerNotes))])
                            ])
                          }
                        )
                      : _vm._e(),
                    appointment.notes != null && appointment.notes != ""
                      ? _c("tr", { staticStyle: { "padding-top": "12px" } }, [
                          _c("th", [
                            _vm._v(
                              _vm._s(_vm.$t("calendar.appointmentNotes")) + ":"
                            )
                          ]),
                          _c("td", [_vm._v(_vm._s(appointment.notes))])
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ])
            ]
          )
        ])
      }),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _vm.appointments.previous != null
            ? _c(
                "app-button",
                {
                  attrs: { cssClass: "e-secondary prev" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.emitPreviousPage.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("calendar.firstAvailability.previous")) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.appointments.next != null
            ? _c(
                "app-button",
                {
                  attrs: { cssClass: "e-primary next" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.emitNextPage.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("calendar.firstAvailability.next")) +
                      " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }