var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ejs-dialog", {
    ref: "appointmentDialog",
    attrs: {
      id: "appointment-dialog",
      isModal: true,
      header: _vm.$t("shared.appointmentsFound", {
        appointmentsNumber: _vm.appointments.data.length
      }),
      animationSettings: _vm.animationSettings,
      content: _vm.cardListTemplate,
      visible: false,
      showCloseIcon: true,
      open: _vm.onOpen
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }